<template>
  <div>
    <b-row class="no-gutters">
      <b-col>
        <h1 class="mr-sm-4 header-tablepage mb-3">Ticket Template Detail</h1>
      </b-col>
    </b-row>

    <div class="bg-white">
      <div class="title-tabs">
        <b-row calss="">
          <b-col md="6" class="text-left d-flex">
            <div>General Information</div>
          </b-col>
        </b-row>
      </div>
      <div class="p-3">
        <b-row>
          <b-col md="6">
            <InputText
              v-model="form.name"
              isRequired
              textFloat="Name"
              placeholder="Name"
              type="text"
              :v="$v.form.name"
              :isValidate="$v.form.name.$error"
            />
          </b-col>

          <b-col cols="12">
            <TextEditorsTiny
              textFloat="Template"
              :rows="4"
              :value="form.detail"
              :name="'template_' + 1"
              placeholder="Type something..."
              :v="$v.form.detail"
              :isValidate="$v.form.detail.$error"
              @onDataChange="(val) => (form.detail = val)"
              isRequired
              :defaultEdit="$route.params.id != 0"
          /></b-col>
          <b-col md="6">
            <InputText
              v-model="form.sort_order"
              textFloat="Sort Order"
              placeholder="Sort Order"
              type="number"
            />
          </b-col>
          <div class="break-normal"></div>
          <div class="no-gutters bg-white mt-3 py-2 px-3">
            <b-form-checkbox
              switch
              v-model="form.active"
              class="radio-active"
              size="lg"
              :value="1"
              :unchecked-value="0"
            >
              <span class="ml-2 main-label">{{
                form.active ? "Active" : "Inactive"
              }}</span>
            </b-form-checkbox>
          </div>
        </b-row>
      </div>
    </div>
 
    <FooterAction routePath="/setting/ticket/template" @submit="submit()" />
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      isLoading: true,
      form: {
        name: "",
        active: 1,
        template: "",
        sort_order: 0,
        detail: "",
      },
      id: this.$route.params.id,
    };
  },
  created() {
    this.getData();
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
        detail: {
          required,
        },
      },
    };
  },
  methods: {
    async getData() {
      if (this.id > 0) {
        this.$bus.$emit("showLoading");
        const res = await this.axios(`/ticket/template/${this.id}`);
        if (res.data.result == 1) {
          this.form = res.data.detail;
        }
        this.$bus.$emit("hideLoading");
      }
    },
    async submit() {
      try {
        this.$v.form.$touch();
        if (this.$v.form.$error) return;
        this.$bus.$emit("showLoading");
        const res = await this.axios.post("/ticket/template", this.form);
        this.$bus.$emit("hideLoading");

        if (res.data.result == 1)
          this.successAlert().then(() =>
            this.$router.push("/setting/ticket/template")
          );
        else this.errorAlert(res.data.message);
      } catch (error) {
        this.$bus.$emit("hideLoading");
        this.errorAlert(error.message);
      }
    },
  },
};
</script>

<style></style>
