<template>
  <div class="ticket-panel">
    <div @click="showTemplate">
      <font-awesome-icon icon="border-all" title="ticket template" />
    </div>
    <b-modal
      centered
      v-model="isShowTemplate"
      title="Ticket Template"
      size="lg"
      hide-footer
    >
      <TemplateList
        view
        @selectTemplate="(value) => onSelectTemplate(value)"
        :templateId="templateId"
      />
      <b-row
        ><b-col
          ><b-button variant="custom-outline-primary" @click="hideTemplate"
            >Cancel</b-button
          ></b-col
        ></b-row
      >
    </b-modal>
  </div>
</template>

<script>
import TemplateList from "@/views/pages/setting/settingPage/Ticket/Template";
export default {
  components: { TemplateList },
  props: {
    templateId: { required: false },
  },
  data() {
    return {
      isShowTemplate: false,
    };
  },
  methods: {
    showTemplate() {
      this.isShowTemplate = true;
    },
    hideTemplate() {
      this.isShowTemplate = false;
    },
    onSelectTemplate(value) {
      this.$emit("selectTemplate", value);
      this.hideTemplate();
    },
  },
};
</script>

<style lang="scss">
.ticket-panel {
  background-color: whitesmoke;
  padding: 0.5rem;
  > div {
    color: black;
    background-color: rgb(225 225 225);
    padding: 0.5rem;
    display: inline-flex;
    font-size: 16px;
    cursor: pointer;
  }
}
</style>
