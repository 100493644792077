<template>
  <div>
    <HeaderPanel
      title="Plugins"
      :filter="filter"
      placeholder=""
      :hasDropdown="false"
      :hasFilter="false"
      :hasSearch="false"
    />

    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <b-container>
        <b-row style="row-gap: 30px">
          <b-col
            lg="4"
            md="6"
            cols="12"
            v-for="list of data"
            :key="'card-' + list.id"
          >
            <b-card class="rounded-xl" no-body style="overflow: hidden">
              <div class="setting-icon">
                <b-dropdown
                  dropleft
                  right
                  center
                  variant="custom-link"
                  toggle-class="text-decoration-none border-none"
                  no-caret
                >
                  <template #button-content>
                    <font-awesome-icon
                      icon="ellipsis-v"
                      class="color-primary"
                    />
                  </template>
                  <b-dropdown-item
                    @click="$router.push('/setting/plugins/' + list.id)"
                    v-if="[1, 3].includes(list.id)"
                    >Info</b-dropdown-item
                  >
                  <b-dropdown-item
                    @click="$router.push('/setting/plugins/logs/' + list.id)"
                    >Logs</b-dropdown-item
                  >
                  <b-dropdown-item @click="openModal(list)"
                    >Setting</b-dropdown-item
                  >
                </b-dropdown>
              </div>
              <div class="p-3">
                <b-img
                  class="image-logo"
                  :src="list.image_url || default_image"
                  @error="handleImageSrc"
                ></b-img>
                <div class="font-bold mt-2">{{ list.name }}</div>
                <p class="two-lines my-1">
                  {{ list.description }}
                </p>
              </div>
              <template #footer>
                <b-form-checkbox
                  switch
                  class="radio-active"
                  size="md"
                  v-model="list.status"
                  :value="1"
                  @change="updateData(false, list)"
                  :unchecked-value="0"
                >
                  <span class="ml-2 main-label">{{
                    list.status == 1 ? "Active" : "Inactive"
                  }}</span>
                </b-form-checkbox>
              </template>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
      <b-modal v-model="modalShow" title="SETTING" @hidden="clearForm">
        <template v-if="form.is_zort">
          <InputText
            textFloat="URL"
            placeholder="URL"
            type="text"
            name="url"
            v-model="form.url"
            :v="$v.form.url"
            :isValidate="$v.form.url.$error"
            isRequired
          />
          <InputText
            textFloat="name"
            placeholder="name"
            type="text"
            name="plugins-name"
            v-model="form.name"
            :v="$v.form.name"
            :isValidate="$v.form.name.$error"
            isRequired
          />
          <InputText
            textFloat="Store Name"
            placeholder="Store Name"
            type="text"
            name="plugins-Store Name"
            v-model="form.storename"
            :v="$v.form.storename"
            :isValidate="$v.form.storename.$error"
            isRequired
          />
          <InputText
            textFloat="API Key"
            placeholder="API Key"
            type="text"
            name="api_key"
            v-model="form.apikey"
            :v="$v.form.apikey"
            :isValidate="$v.form.apikey.$error"
            isRequired
          />
          <InputText
            textFloat="API Secret"
            placeholder="API Secret"
            type="text"
            name="api_key"
            v-model="form.apisecret"
            :v="$v.form.apisecret"
            :isValidate="$v.form.apisecret.$error"
            isRequired
          />
          <InputSelect
            title="Order Status"
            name="Order Status"
            :options="orderStatus"
            v-model="form.order_status"
            valueField="id"
            textField="name"
          >
            <!-- :v="$v.form.order_status"
            :isValidate="$v.form.order_status.$error" -->
            <template v-slot:option-first>
              <b-form-select-option :value="null" disabled
                >--- Select order status ---
              </b-form-select-option>
            </template>
          </InputSelect>
          <InputSelect
            title="Order Payment Status"
            name="Order Payment Status"
            :options="paymentStatus"
            v-model="form.order_paymentstatus"
            valueField="id"
            textField="name"
          >
            <!-- :v="$v.form.order_paymentstatus"
            :isValidate="$v.form.order_paymentstatus.$error" -->
            <template v-slot:option-first>
              <b-form-select-option :value="null" disabled
                >--- Select payment status ---
              </b-form-select-option>
            </template>
          </InputSelect>
        </template>
        <template v-else-if="form.id == 6">
          <InputText
            textFloat="URL"
            placeholder="URL"
            type="text"
            name="url"
            v-model="form.url"
            :v="$v.form.url"
            :isValidate="$v.form.url.$error"
            isRequired
          />

          <InputText
            textFloat="Token"
            placeholder="Token"
            type="text"
            name="token"
            v-model="form.token"
            :v="$v.form.token"
            :isValidate="$v.form.token.$error"
            isRequired
          />
          <InputText
            textFloat="Pixel ID."
            placeholder="Pixel ID."
            type="text"
            name="pixel_id"
            v-model="form.pixel_id"
            :v="$v.form.pixel_id"
            :isValidate="$v.form.pixel_id.$error"
            isRequired
          />
          <InputText
            textFloat="Lastest Day"
            placeholder="Lastest Day"
            type="number"
            name="latest_day"
            v-model="form.latest_day"
            :v="$v.form.latest_day"
            :isValidate="$v.form.latest_day.$error"
            isRequired
          />
        </template>
        <template v-else-if="form.id == 7">
          <InputText
            :textFloat="'Client ID.'"
            placeholder="Client ID."
            type="text"
            autocomplete="a"
            name="plugins-client-id"
            v-model="form.username"
            :v="$v.form.username"
            :isValidate="$v.form.username.$error"
            isRequired
          />
          <InputText
            textFloat="User ID."
            placeholder="User ID."
            name="plugins-user-id"
            v-model="form.password"
            :v="$v.form.password"
            :isValidate="$v.form.password.$error"
            isRequired
          />
          <InputText
            textFloat="Tenant ID"
            placeholder="Tenant ID"
            type="text"
            name="tenant_id"
            v-model="form.token"
            :v="$v.form.token"
            :isValidate="$v.form.token.$error"
            isRequired
          />
          <InputText
            textFloat="Client Secret"
            placeholder="Client Secret"
            type="text"
            name="client_secret"
            v-model="form.apikey"
            :v="$v.form.apikey"
            :isValidate="$v.form.apikey.$error"
            isRequired
          />
          <div class="text-error" v-if="connectionErrorMessage.status">
            {{ connectionErrorMessage.message }}
          </div>
          <div
            class="text-underline cursor-pointer"
            @click="testSendEmailPlugin(true)"
          >
            Test Connection
          </div>
        </template>
        <template v-else>
          <InputText
            textFloat="URL"
            placeholder="URL"
            type="text"
            name="url"
            v-model="form.url"
            :v="$v.form.url"
            :isValidate="$v.form.url.$error"
            isRequired
          />
          <InputText
            :textFloat="form.id == 5 ? 'Encrypt Head' : 'Username'"
            placeholder="Username"
            type="text"
            autocomplete="a"
            name="plugins-username"
            v-model="form.username"
            :v="$v.form.username"
            v-if="form.id != 3 && form.id != 4"
            :isValidate="$v.form.username.$error"
            isRequired
          />
          <InputText
            textFloat="Password"
            placeholder="Password"
            type="password"
            name="plugins-password"
            v-model="form.password"
            :v="$v.form.password"
            :isValidate="$v.form.password.$error"
            isRequired
            v-if="form.id != 3 && form.id != 4 && form.id != 5"
          />
          <InputText
            textFloat="Token"
            placeholder="Token"
            type="text"
            name="token"
            v-model="form.token"
            :v="$v.form.token"
            :isValidate="$v.form.token.$error"
            isRequired
            v-if="form.id == 3 || form.id == 4"
          />
          <InputText
            :textFloat="form.id == 5 ? 'Passkey' : 'API Key'"
            :placeholder="form.id == 5 ? 'Passkey' : 'API Key'"
            type="text"
            name="api_key"
            v-model="form.apikey"
            :v="$v.form.apikey"
            :isValidate="$v.form.apikey.$error"
            isRequired
            v-if="form.id != 3 && form.id != 4"
          />
          <InputText
            :textFloat="form.id == 5 ? 'URL Origin' : 'Brand Code'"
            :placeholder="form.id == 5 ? 'URL Origin' : 'Brand Code'"
            type="text"
            name="brand_code"
            v-model="form.brand_code"
            :v="$v.form.brand_code"
            :isValidate="$v.form.brand_code.$error"
            isRequired
            v-if="form.id != 4"
          />

          <InputText
            textFloat="Channel ID"
            placeholder="Channel ID"
            type="text"
            name="channel_id"
            v-model="form.chanel_id"
            :v="$v.form.chanel_id"
            :isValidate="$v.form.chanel_id.$error"
            isRequired
            v-if="form.id != 3 && form.id != 4 && form.id != 5"
          />
          <InputText
            textFloat="Mock Email (if Email is Empty)"
            placeholder="Email"
            type="text"
            name="email-mock"
            v-model="form.mock_email"
            :v="$v.form.mock_email"
            :isValidate="$v.form.mock_email.$error"
            isRequired
            v-if="form.id != 3 && form.id != 4 && form.id != 5"
          />
          <div v-if="form.id != 3 && form.id != 4 && form.id != 5">
            <label class="main-label">
              Mock Birthday (if Birthday is Empty)
              <span class="text-danger">*</span>
            </label>
            <div
              :class="[
                'input-container',
                { error: $v.form.mock_birthday.$error },
              ]"
            >
              <datetime
                type="date"
                v-model="form.mock_birthday"
                placeholder="DD/MM/YYYY"
                format="dd/MM/yyyy"
                value-zone="Asia/Bangkok"
                ref="birthdate"
                :isValidate="$v.form.mock_birthday.$error"
                :v="$v.form.mock_birthday"
              >
              </datetime>
              <div
                :class="'icon-primary text-right cursor-default'"
                @click="$refs.birthdate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  :class="'pointer color-primary cursor-default color-primary'"
                  color="#B41BB4"
                />
              </div>
            </div>
          </div>
          <div
            v-if="
              $v.form.mock_birthday.$error &&
              form.id != 3 &&
              form.id != 4 &&
              form.id != 5
            "
          >
            <span
              class="text-error"
              v-if="$v.form.mock_birthday.required == false"
              >Please select date.</span
            >
          </div>
        </template>
        <template #modal-footer>
          <b-row class="w-100 justify-content-between" no-gutters>
            <b-col cols="4">
              <b-button
                block
                @click.prevent="modalShow = false"
                class="border-btn"
                >Cancel</b-button
              >
            </b-col>

            <b-col cols="4">
              <b-button
                block
                class="submit-btn"
                @click.prevent="updateData(true)"
                >Confirm</b-button
              >
            </b-col>
          </b-row>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { required, email, requiredIf } from "vuelidate/lib/validators";
const isValidUrl = (string, data) => {
  try {
    if (data.id == 7) return true;

    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
};
export default {
  data() {
    return {
      filter: {},
      data: [],
      modalShow: false,
      form: {
        id: 0,
        name: "",
        image_url: null,
        description: null,
        status: 1,
        url: "",
        username: "",
        password: "",
        brand_code: "",
        chanel_id: "",
        mock_email: "",
        mock_birthday: "",
        token: null,
        apisecret: null,
        apikey: null,
        storename: null,
        order_status: null,
        order_paymentstatus: null,
      },
      orderStatus: [
        { name: "Pending", id: "Pending" },
        { name: "Success", id: "Success" },
        { name: "Voided", id: "Voided" },
        { name: "Waiting", id: "Waiting" },
        { name: "ไม่ใส่", id: "" },
      ],
      paymentStatus: [
        { name: "Pending", id: "Pending" },
        { name: "Paid", id: "Paid" },
        { name: "Voided", id: "Voided" },
        { name: "Waiting", id: "Waiting" },
        { name: "Partial", id: "Partial" },
        { name: "Overpaid", id: "Overpaid" },
        { name: "ไม่ใส่", id: "" },
      ],
      connectionErrorMessage: {
        message: "",
        status: false,
      },
    };
  },
  validations() {
    return {
      form: {
        url: {
          isValidUrl: (val) => isValidUrl(val, this.form),
          required: requiredIf(function (item) {
            return item.id != 7;
          }),
        },
        apikey: {
          required: requiredIf(function (item) {
            return [1, 2, 5, 7].includes(item.id);
          }),
        },
        token: {
          required: requiredIf(function (item) {
            return [3, 4, 6, 7].includes(item.id);
          }),
        },
        apisecret: {
          required: requiredIf(function (item) {
            return item.is_zort && [1, 2].includes(item.id);
          }),
        },
        username: {
          required: requiredIf(function (item) {
            return !item.is_zort && [1, 2, 4, 5, 7].includes(item.id);
          }),
        },
        password: {
          required: requiredIf(function (item) {
            return !item.is_zort && [1, 2, 7].includes(item.id);
          }),
        },
        chanel_id: {
          required: requiredIf(function (item) {
            return !item.is_zort && [1, 2].includes(item.id);
          }),
        },
        brand_code: {
          required: requiredIf(function (item) {
            return (
              !item.is_zort && item.id != 4 && item.id != 6 && item.id != 7
            );
          }),
        },
        mock_email: {
          required: requiredIf(function (item) {
            return !item.is_zort && [1, 2].includes(item.id);
          }),
          email,
        },
        mock_birthday: {
          required: requiredIf(function (item) {
            return !item.is_zort && [1, 2].includes(item.id);
          }),
        },
        storename: {
          required: requiredIf(function (item) {
            return item.is_zort && [1, 2].includes(item.id);
          }),
        },
        pixel_id: {
          required: requiredIf(function (item) {
            return item.id == 6;
          }),
        },
        latest_day: {
          required: requiredIf(function (item) {
            return item.id == 6;
          }),
        },
        name: {
          required: requiredIf(function (item) {
            return item.is_zort;
          }),
        },
      },
    };
  },
  created() {
    this.getData();
    // this.getDataById();
  },
  methods: {
    clearForm() {
      this.$v.form.$reset();
      this.form = {
        id: 0,
        name: "",
        image_url: "",
        description: "",
        status: "",
        url: "",
        username: "",
        password: "",
        brand_code: "",
        chanel_id: "",
        mock_email: "",
        mock_birthday: "",
        key: "",
        token: "",
      };
    },
    openModal(form) {
      if (form.id == 2 || form.name.toLowerCase() == "zort") {
        this.getZortDetail();
      } else {
        this.form = { ...form };
      }
      this.modalShow = true;
    },
    async getZortDetail() {
      const res = await this.axios(`/plugin/pluginType/2`);

      // this.data = res.data.detail;
      this.form = res.data.detail;
      this.form.is_zort = true;
    },
    async getData() {
      const res = await this.axios(`/Plugin/GetList`);

      this.data = res.data.detail;
    },

    async updateData(check, list = this.form) {
      try {
        let payload = { ...list };
        payload.is_pdpa = payload.id == 1 ? 1 : 0;
        if (this.form.id != 3)
          payload.mock_birthday = this.$moment(payload.mock_birthday).format(
            "YYYY-MM-DD"
          );

        payload.chanel_id = payload.chanel_id || 0;

        this.$v.form.$touch();

        if (check && this.$v.form.$error) return;
        if (payload.id == 7 && check) {
          const test = await this.testSendEmailPlugin(false);
          if (!test) return;
        }
        this.$bus.$emit("showLoading");
        const res = await this.axios.put(`/Plugin/pluginType/save`, payload);

        this.$bus.$emit("hideLoading");
        if (res.data.result) {
          this.modalShow = false;
          this.successAlert();
          this.getData();
        } else {
          this.errorAlert(res.data.message);
        }
      } catch (error) {
        console.log(error);
        this.$bus.$emit("hideLoading");
        this.errorAlert(error.message);
      }
    },
    async testSendEmailPlugin(flag = true) {
      return new Promise(async (resolve, reject) => {
        try {
          this.connectionErrorMessage = {
            message: "",
            status: false,
          };
          let payload = { ...this.form };
          payload.is_pdpa = payload.id == 1 ? 1 : 0;
          if (this.form.id != 3)
            payload.mock_birthday = this.$moment(payload.mock_birthday).format(
              "YYYY-MM-DD"
            );

          payload.chanel_id = payload.chanel_id || 0;

          this.$bus.$emit("showLoading");
          const res = await this.axios.post(`/plugin/validticketmail`, payload);

          this.$bus.$emit("hideLoading");
          if (res.data.result) {
            // this.modalShow = false;
            if (flag) this.successAlert(`Connection Success`);
            // this.getData();
            resolve(true);
          } else {
            this.connectionErrorMessage = {
              message: res.data.message,
              status: true,
            };
            resolve(false);
          }
        } catch (error) {
          this.$bus.$emit("hideLoading");
          this.errorAlert(error.message);
          resolve(false);
        }
      });
    },
  },
};
</script>

<style>
.image-logo {
  border-radius: 0.5rem;
  width: 75px;
  height: auto;
  aspect-ratio: 1;
}
.card-footer {
  padding: 0.75rem 1rem;
}
.setting-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}
.dropdown-menu {
  min-width: unset;
  width: max-content;
}
.dropdown-item.active,
.dropdown-item:active {
  text-decoration: none;
  background-color: #fff;
  color: var(--primary-color) !important;
}
</style>
