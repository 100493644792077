<template>
  <div>
    <b-row class="no-gutters">
      <b-col>
        <h1 class="mr-sm-4 header-tablepage mb-3">USER MANUAL</h1>
      </b-col>
    </b-row>
    <div class="bg-white p-3">
      <InputText placeholder="Search Manual Guide" v-model="search" />
      <b-row class="align-items-center row-gap-lg">
        <template v-for="item of filterItems">
          <b-col cols="1" :key="item.id">
            <div
              class="icon-guide cursor-pointer"
              @click="openLink(item.tutorial)"
            >
              <font-awesome-icon icon="book"></font-awesome-icon>
            </div>
          </b-col>
          <b-col :key="'name' + item.id">
            <span
              class="cursor-pointer text-underline"
              @click="openLink(item.tutorial)"
            >
              {{ item.name }}
              <input type="hidden" :ref="'text-' + item.id" />
            </span>
          </b-col>
          <div class="break-normal" :key="'break' + item.id"></div>
        </template>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      items: [],
    };
  },
  watch: {},
  computed: {
    filterItems() {
      return this.search
        ? this.items.filter((el) =>
            el.name.toLowerCase().includes(this.search.toLowerCase())
          )
        : this.items;
    },
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await this.axios("setting/GetUserManual");
      this.items = res.data.detail;
    },
    openLink(tutorial) {
      window.open(tutorial, "_blank");
    },
  },
};
</script>

<style>
.icon-guide {
  background-color: var(--theme-secondary-color);
  width: 50px;
  height: 50px;
  border-radius: 10px;
  color: var(--primary-color);
  padding: 10px;
  text-align: center;
  font-size: 18px;
}
</style>
